.mainWrapper {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 360px;
  height: 420px;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0,0,0,.08);

  button {
    width: 100%;
  }

  p {
    color:#333333;
    text-align: center;
    margin-top: 16px;

    span {
      &:first-child {
        margin-right: 16px;
      }
    }
  }

  span {
    vertical-align: text-bottom;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
  }
  
  .logoWrapper {
    text-align: center;
    height: 120px;
    line-height: 40px;
    cursor: pointer;
    margin-bottom: 14px;
  
    .logoImg {
      width: 122px;
      /*margin-right: 8px;*/
    }
  }
  
  .loginErrorMessage,
  .recoverErrorMessage {
    font-size: 14px;
    color: red;
  }

  .recoverSuccessMessage {
    font-size: 14px;
    color: green;
  }

}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100vh!important;
}
